import React from 'react';

interface PlayPauseButtonProps {
  toggleRun: () => void;
  isRunning: () => boolean;
  currentIndex: () => number;
  maxIndex: number;
}

const PlayPauseButton: React.FC<PlayPauseButtonProps> = ({ toggleRun, isRunning, currentIndex, maxIndex }) => {
  const handlePlayIcon = () => {
    if (isRunning()) {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="6" y="4" width="4" height="16" fill="#e0e0e0"/>
          <rect x="14" y="4" width="4" height="16" fill="#e0e0e0"/>
        </svg>
      )
    } else if (currentIndex() === maxIndex) {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C15.73 20 18.84 17.45 19.73 14H17.65C16.83 16.33 14.61 18 12 18C8.69 18 6 15.31 6 12C6 8.69 8.69 6 12 6C13.66 6 15.14 6.69 16.22 7.78L13 11H20V4L17.65 6.35Z" fill="#e0e0e0"/>
        </svg>
      )
    } else {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 5V19L19 12L8 5Z" fill="#e0e0e0"/>
        </svg>
      )
    }
  }

  return (
    <button onClick={toggleRun}>{handlePlayIcon()}</button>
  );
};

export default PlayPauseButton;
