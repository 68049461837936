import React, {useEffect, useState} from "react";
import * as d3 from 'd3'
import Snapshot from "../../Algorithms/Sorting/Snapshot/Snapshot";
import Bar from "../Bar/Bar";

interface SortingReactChartProps {
    width: number,
    height: number,
    snapshot: Snapshot
}

const BarChart: React.FC<SortingReactChartProps> = ({width, height, snapshot}) => {
    const [xScale, setXScale] = useState<d3.ScaleBand<string>>()
    const [yScale, setYScale] = useState<d3.ScaleLinear<number, number>>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const values = snapshot.array.map(s => s.value);
        const ids = snapshot.array.map(s => s.id);
        const maxBarHeight = d3.max(values)
        if (maxBarHeight) {
            setYScale(() => d3.scaleLinear()
                .domain([0, maxBarHeight])
                .range([height, 0]))

            setXScale(() => d3.scaleBand()
                .domain(ids.map(d => d.toString()))
                .range([0, width])
                .padding(.1)
            )
            setLoading(false)
        }

    }, [width, height, snapshot])


    if (!loading && xScale && yScale) {
        return (

            <g>
                {snapshot.array
                    .map((d, i) => (
                        <Bar
                            key={d.id.toString()}
                            type={snapshot.highlight(i)}
                            x={xScale('' + d.id) || 0}
                            y={yScale(d.value)}
                            width={xScale.bandwidth()}
                            height={height - yScale(d.value)}
                        />))}
            </g>
        )
    }
    return <></>

}

export default BarChart