import React from 'react';
import { Link } from 'react-router-dom';

interface AlgorithmCardProps {
  title: string;
  imageSrc: string;
  link: string;
}

const AlgorithmCard: React.FC<AlgorithmCardProps> = ({ title, imageSrc, link }) => {
  const isActive = link !== '/';
  
  return (
    <div className={`algorithm-card ${isActive ? '' : 'inactive'}`}>
      <Link to={link} className="algorithm-card-link">
        <h2>{title}</h2>
        <img src={imageSrc} alt={title} />
      </Link>
    </div>
  );
};

export default AlgorithmCard;
