import Snapshot from "../Snapshot/Snapshot";

class MergeSortSnapshot extends Snapshot {
    copy(): MergeSortSnapshot {
        return new MergeSortSnapshot(
            this.array,
            new Map(this.tags),
            new Map(this.invertedTags)
        );
    }

    highlight(index: number): string {
        const start = this.tags.get('start') ?? 0;
        const middle = this.tags.get('middle') ?? 0;
        const end = this.tags.get('end') ?? this.array.length;
        const sortedEnd = this.tags.get('sortedEnd') ?? start;

        if (index < start || index >= end) {
            return 'inactive';
        }

        if (index < sortedEnd) {
            return 'sorted';
        }

        if (index === this.tags.get('leftIndex') || index === this.tags.get('rightIndex')) {
            return 'current-pointer';
        }

        if (index >= start && index < middle) {
            return 'left-subarray';
        }
        if (index >= middle && index < end) {
            return 'right-subarray';
        }

        return 'default';
    }
}

export default MergeSortSnapshot;
