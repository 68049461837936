import React, { ChangeEvent, useCallback, useEffect } from 'react';
import './ProgressRange.css';

interface ProgressRangeProps {
    maxIndex: number;
    currentIndex: () => number;
    setIndex: (index: number) => void;
}

const ProgressRange: React.FC<ProgressRangeProps> = ({ maxIndex, currentIndex, setIndex }) => {
    const handleRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setIndex(value);
        updateRangeBackground(value);
    } 

    const updateRangeBackground = useCallback((value: number) => {
        const percentage = (value / maxIndex) * 100;
        const rangeInput = document.querySelector('.progress-range') as HTMLInputElement;
        if (rangeInput) {
            rangeInput.style.background = `linear-gradient(to right, #ff69b4 0%, #ff69b4 ${percentage}%, #333 ${percentage}%, #333 100%)`;
        }
    }, [maxIndex])

    useEffect(() => {
        updateRangeBackground(currentIndex());
    }, [currentIndex, maxIndex, updateRangeBackground]);

    return (
        <input 
            className='progress-range'
            type='range'
            min={0}
            max={maxIndex}
            value={currentIndex()}
            onChange={handleRangeChange}
        />
    );
}

export default ProgressRange;
