import ArrayElement from "../common/ArrayElement"

abstract class Snapshot {
    #array: ArrayElement[] = []
    #tags: Map<string, number> = new Map()
    #invertedTags: Map<number, string> = new Map()

    constructor(
        array: ArrayElement[],
        tags?: Map<string, number>,
        invertedTags?: Map<number, string>
    ) {
        this.#array = array;
        if (tags) this.#tags = tags;
        if (invertedTags) this.#invertedTags = invertedTags;
    }

    abstract copy(): Snapshot;

    abstract highlight(index: number): string;

    withArray = (array: ArrayElement[]): Snapshot => {
        return this.copy().setArray(array);
    }

    withTag = (tag: string, value: number): Snapshot => {
        const newTags = new Map(this.#tags).set(tag, value);
        const newInvertedTags = new Map(this.#invertedTags).set(value, tag);
        return this.copy().setTags(newTags, newInvertedTags);
    }

    withTags = (tags: Map<string, number>): Snapshot => {
        const newInvertedTags = new Map(this.#invertedTags);
        const newTags = new Map(this.#tags);
        tags.forEach((value, key) => {
            newInvertedTags.set(value, key);
            newTags.set(key, value);
        });
        return this.copy().setTags(newTags, newInvertedTags);
    }

    protected setArray(array: ArrayElement[]): this {
        this.#array = array;
        return this;
    }

    protected setTags(tags: Map<string, number>, invertedTags: Map<number, string>): this {
        this.#tags = tags;
        this.#invertedTags = invertedTags;
        return this;
    }

    get array(): ArrayElement[] {
        return this.#array;
    }

    get tags(): Map<string, number> {
        return this.#tags;
    }

    get invertedTags(): Map<number, string> {
        return this.#invertedTags;
    }
}

export default Snapshot;
