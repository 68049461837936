import React from 'react';
import AlgorithmCard from '../AlgorithmCard/AlgorithmCard';

const SortingAlgorithmsGrid = () => {
    const sortingAlgorithms = [
        { title: 'Selection Sort', imageSrc: '/images/sorting_bars.webp', link: '/sorting/selection-sort' },
        { title: 'Bubble Sort', imageSrc: '/images/sorting_bars.webp', link: '/sorting/bubble-sort' },
        { title: 'Insertion Sort', imageSrc: '/images/sorting_bars.webp', link: '/sorting/insertion-sort' },
        { title: 'Merge Sort', imageSrc: '/images/sorting_bars.webp', link: '/sorting/merge-sort' },
        { title: 'Quick Sort', imageSrc: '/images/sorting_bars.webp', link: '/sorting/quick-sort' },
        { title: 'Heap Sort', imageSrc: '/images/sorting_bars.webp', link: '/sorting/heap-sort' },
    ];

    return (
        <div className="algorithm-grid">
            <h1>Sorting Algorithms</h1>
            <div className="algorithm-cards">
                {sortingAlgorithms.map((algorithm, index) => (
                    <AlgorithmCard
                        key={index}
                        title={algorithm.title}
                        imageSrc={algorithm.imageSrc}
                        link={algorithm.link}
                    />
                ))}
            </div>
        </div>
    );
};

export default SortingAlgorithmsGrid;
