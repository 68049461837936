import Snapshot from "../Snapshot/Snapshot";

class InsertionSortSnapshot extends Snapshot {
    copy(): InsertionSortSnapshot {
        return new InsertionSortSnapshot(
            this.array,
            new Map(this.tags),
            new Map(this.invertedTags)
        );
    }

    highlight(index: number): string {
        switch (index) {
            case this.tags.get('i'):
                return 'green';
            case this.tags.get('j'):
                return 'yellow';
            case this.tags.get('min'):
                return 'red';
        }
        if (index < (this.tags.get('i') ?? -1)) {
            return 'orange';
        }
        return 'skyblue';
    }
}

export default InsertionSortSnapshot;
