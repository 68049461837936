import Snapshots from "../common/Snapshots";
import { SortingVisualization } from "../../../Utils/SortingVisualization";
import BubbleSortSnapshot from "./BubbleSortSnapshot";

const BubbleSort = (data: number[]) => {
    const s = new Snapshots(BubbleSortSnapshot);

    let array = data.map((v, i) => ({
        id: i,
        value: v
    }))
    s.withArray(array)

    for (let i = 0; i < array.length; i++) {
        s.withTag("sorted", array.length - i);
        for (let j = 0; j < array.length - i - 1; j++) {
            s.withTag("j", j)
            if (array[j].value > array[j + 1].value) {
                s.withTags(["swap1", j], ["swap2", j + 1])
                const temp = array[j]
                array[j] = array[j + 1]
                array[j + 1] = temp
                s.resetTags("swap1", "swap2")
                s.withArray(array)
            }
        }
    }
    s.withTags(["sorted", 0], 
        ["i", Number.MAX_SAFE_INTEGER], 
        ["j", Number.MAX_SAFE_INTEGER])



    console.log('Snapshots: ', s.snapshots)
    return new SortingVisualization(data, s.snapshots)
}

export default BubbleSort
