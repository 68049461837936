import Snapshot from "../Snapshot/Snapshot";

class BubbleSortSnapshot extends Snapshot {
    copy(): BubbleSortSnapshot {
        return new BubbleSortSnapshot(
            this.array,
            new Map(this.tags),
            new Map(this.invertedTags)
        );
    }

    highlight(index: number): string {
        switch (index) {
            case this.tags.get('swap1'):
                return 'red';
            case this.tags.get('swap2'):
                return 'red';
            case this.tags.get('j'):
                return 'yellow';
        }
        if (index >= (this.tags.get('sorted') ?? Number.MAX_SAFE_INTEGER)) {
            return 'orange';
        }
        return 'skyblue';
    }
}

export default BubbleSortSnapshot;
