import Snapshots from "../common/Snapshots";
import { SortingVisualization } from "../../../Utils/SortingVisualization";
import InsertionSortSnapshot from "./InsertionSortSnapshot";
import ArrayElement from "../common/ArrayElement";
import BaseSortingAlgorithm from "../BaseSortingAlgorithm";

const InsertionSort: BaseSortingAlgorithm = (data: number[]): SortingVisualization => {
    const s = new Snapshots(InsertionSortSnapshot);

    let array: ArrayElement[] = data.map((v, i) => ({
        id: i,
        value: v
    }));
    let min = -1;
    s.withArray(array);

    for (let i = 1; i < array.length; i++) {
        s.withTag("i", i);
        for (let j = i - 1; j >= 0; j--) {
            s.withTag("j", j);
            if (array[i].value < array[j].value) {
                min = j;
            }
            if (array[i].value > array[j].value)
                break;
        }
        s.withTag("min", min);
        if (min !== -1) {
            const value = array[i];
            array.splice(i, 1);
            array.splice(min, 0, value);
            s.withArray(array);
        }
        min = -1;
        s.withTag("min", min);
    }
    s.withTags(["i", Number.MAX_SAFE_INTEGER], ["j", Number.MAX_SAFE_INTEGER]);

    return new SortingVisualization(data, s.snapshots);
}

export default InsertionSort;
