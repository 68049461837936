import React from 'react';
import AlgorithmCard from '../AlgorithmCard/AlgorithmCard';

const AlgorithmGrid = () => {
    const algorithms = [
        { title: 'Sorting', imageSrc: '/images/sorting.webp', link: '/sorting' },
        { title: 'Data Structures', imageSrc: '/images/data_structures.webp', link: '/' },
        { title: 'Graph Algorithms', imageSrc: '/images/graph_algorithms.webp', link: '/' },
        { title: 'String Algorithms', imageSrc: '/images/string_algorithms.webp', link: '/' },
        { title: 'Dynamic Programming', imageSrc: '/images/dynamic_programming.webp', link: '/' },
        { title: 'Greedy Algorithms', imageSrc: '/images/greedy_algorithms.webp', link: '/' },
        { title: 'Divide and Conquer', imageSrc: '/images/divide_and_conquer.webp', link: '/' },
        { title: 'Backtracking', imageSrc: '/images/backtracking.webp', link: '/' },
    ];

    return (
        <div className="algorithm-grid">
            <h1>Algorithm Visualizations</h1>
            <div className="algorithm-cards">
                {algorithms.map((algorithm, index) => (
                    <AlgorithmCard
                        key={index}
                        title={algorithm.title}
                        imageSrc={algorithm.imageSrc}
                        link={algorithm.link}
                    />
                ))}
            </div>
        </div>
    );
};

export default AlgorithmGrid;
