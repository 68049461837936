import SVGContainer from "../SVGContainer";
import BarChart from "../BarChart/BarChart";
import VisualizationControls from "../../Components/VisualizationControls/VisualizationControls";
import React, {useEffect, useState} from "react";
import {SortingVisualization} from "../../Utils/SortingVisualization";

interface AlgorithmVisualizationProps {
    visualization: SortingVisualization;
    onShuffle: () => void;
}

const AlgorithmVisualization: React.FC<AlgorithmVisualizationProps> = ({visualization, onShuffle}) => {
    const [, setUpdate] = useState(0);

    const handleUpdate = () => setUpdate(prevState => prevState + 1);

    useEffect(() => {
        // Set up the handleUpdate for the current algorithm instance
        visualization.handleUpdate = handleUpdate;
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            visualization.handleUpdate = () : void => {};
        };
    }, [visualization]);

    const snapshot = visualization.getCurrentSnapshot();

    return (
        <>
            <SVGContainer width={1500} height={750}>
                <BarChart snapshot={snapshot} width={1300} height={500}/>
            </SVGContainer>
            <div className={'control-panel'}>
                <VisualizationControls 
                    maxIndex={visualization.maxIndex()}
                    incrementRange={() => {
                        visualization.currentIndex += 1;
                        visualization.stop();
                    }}
                    decrementRange={() => {
                        visualization.currentIndex -= 1;
                        visualization.stop();
                    }}
                    resetRangeToStart={visualization.resetRangeToStart}
                    resetRangeToEnd={visualization.resetRangeToEnd}
                    currentIndex={() => visualization.currentIndex}
                    setIndex={visualization.setRangeTo}
                    toggleRun={visualization.toggleRun}
                    isRunning={visualization.isRunning}
                    shuffle={onShuffle}
                    changeAnimationSpeed={visualization.setAnimationDelay}
                    getAnimationSpeed={visualization.getAnimationDelay}
                />
            </div>
        </>
    );
};

export default AlgorithmVisualization;
