import Snapshots from "../common/Snapshots";
import { SortingVisualization } from "../../../Utils/SortingVisualization";
import SelectionSortSnapshot from "./SelectionSortSnapshot";


const SelectionSort = (data: number[]): SortingVisualization => {
    const s = new Snapshots(SelectionSortSnapshot);

    const array = data.map((v, i) => ({
        id: i,
        value: v
    }));

    s.withArray(array);

    if (array.length > 2) {
        for (let i = 0; i < array.length; i++) {
            let min = i;
            s.withTags(["i", i], ["min", min]);
            for (let j = i + 1; j < array.length; j++) {
                s.withTag("j", j);
                if (array[j].value < array[min].value) {
                    min = j;
                    s.withTag("min", min);
                }
            }
            s.resetTag("j");
            const temp = array[min];
            array[min] = array[i];
            array[i] = temp;
            min = -1;
            s.withArray(array);
            s.withTag("min", min);
        }
    }
    s.withTags(["i", Number.MAX_SAFE_INTEGER])
    s.resetTags("min", "j")



    return new SortingVisualization(data, s.snapshots);
}

export default SelectionSort;
