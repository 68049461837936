import React from 'react';

interface ResetToEndButtonProps {
  resetRangeToEnd: () => void;
}

const ResetToEndButton: React.FC<ResetToEndButtonProps> = ({ resetRangeToEnd }) => (
  <button onClick={resetRangeToEnd}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6H14V18H18V6ZM6 6L12.5 12L6 18V6Z" fill="#e0e0e0"/>
    </svg>
  </button>
);

export default ResetToEndButton;
