export function createRandomArray(numberOfElements: number) {
    return Array.from({length: numberOfElements}, () => 1 + Math.floor(Math.random() * 100));
}

export function createSortedArray(numberOfElements: number) {
    return Array.from({length: numberOfElements}, (_, i) => i + 1);
}

export function createReverseSortedArray(numberOfElements: number) {
    return Array.from({length: numberOfElements}, (_, i) => numberOfElements - i);
}

export function createNearlySortedArray(numberOfElements: number, swaps = 5) {
    const arr = createSortedArray(numberOfElements);
    for (let i = 0; i < swaps; i++) {
        const index1 = Math.floor(Math.random() * numberOfElements);
        const index2 = Math.floor(Math.random() * numberOfElements);
        [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    }
    return arr;
}

export function createArrayWithManyDuplicates(numberOfElements: number, uniqueElements = 5) {
    const uniqueValues = Array.from({length: uniqueElements}, (_, i) => i + 1);
    return Array.from({length: numberOfElements}, () => uniqueValues[Math.floor(Math.random() * uniqueElements)]);
}

export function createArrayWithFewUniqueElements(numberOfElements: number) {
    return Array.from({length: numberOfElements}, () => Math.floor(Math.random() * 3) + 1);
}

export function createSawtoothArray(numberOfElements: number, patternLength = 10) {
    return Array.from({length: numberOfElements}, (_, i) => (i % patternLength) + 1);
}

export function createArrayWithOutliers(numberOfElements: number, outlierPercentage = 0.1) {
    const arr = createRandomArray(numberOfElements);
    const outlierCount = Math.floor(numberOfElements * outlierPercentage);
    for (let i = 0; i < outlierCount; i++) {
        const index = Math.floor(Math.random() * numberOfElements);
        arr[index] = Math.floor(Math.random() * 1000) + 100; // Outliers are significantly larger
    }
    return arr;
}
