import React from 'react';

interface ShuffleButtonProps {
  shuffle: () => void;
}

const ShuffleButton: React.FC<ShuffleButtonProps> = ({ shuffle }) => (
  <button onClick={shuffle}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.59 9.17L5.41 4L4 5.41L9.17 10.58L10.59 9.17ZM14.5 4L16.54 6.04L4 18.59L5.41 20L17.96 7.46L20 9.5V4H14.5ZM14.83 13.41L13.42 14.82L16.55 17.95L14.5 20H20V14.5L17.96 16.54L14.83 13.41Z" fill="#e0e0e0"/>
    </svg>
  </button>
);

export default ShuffleButton;
