import Snapshot from "../Algorithms/Sorting/Snapshot/Snapshot"

export class SortingVisualization {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private _handleUpdate: () => void = () => {}
    private _array: number[]
    private _snapshots: Snapshot[]
    private _currentIndex = 0
    private _interval: NodeJS.Timeout | null = null
    private _animationDelay = 100

    constructor(array: number[], snapshots: Snapshot[] = []) {
        this._array = array
        this._snapshots = snapshots
    }

    get handleUpdate(): (() => void) {
        return this._handleUpdate
    }

    set handleUpdate(value: (() => void)) {
        this._handleUpdate = value
    }

    get currentIndex(): number {
        return this._currentIndex
    }

    set currentIndex(value: number) {
        if (value >= 0 && value <= this.maxIndex()) {
            this._currentIndex = value
            this.triggerUpdate()
        } else if (value > this.maxIndex()) {
            this.stop()
        }
    }
    private get interval(): NodeJS.Timeout | null {
        return this._interval
    }

    private set interval(value: NodeJS.Timeout | null) {
        this._interval = value
        this.triggerUpdate()
    }

    private get array(): number[] {
        return this._array
    }

    private set array(value: number[]) {
        this._array = value
    }

    private get snapshots(): Snapshot[] {
        return this._snapshots
    }

    private set snapshots(value: Snapshot[]) {
        this._snapshots = value
    }

    getAnimationDelay = (): number => {
        return this._animationDelay
    }

    setAnimationDelay = (value: number) => {
        this._animationDelay = value
        if (this.isRunning()) {
            this.stop()
            this.run()
        } else {
            this.triggerUpdate()
        }
    }

    maxIndex = () => {
        return this.snapshots.length - 1
    }

    getCurrentSnapshot = () => {
        console.log(`this._currentIndex: ${this._currentIndex}`)
        console.log(`this.snapshots.length: ${this.snapshots.length}`)
        console.log(`this.snapshots[this._currentIndex]: ${JSON.stringify(this.snapshots[this._currentIndex])}`)
        return this.snapshots[this._currentIndex]
    }

    shuffle = () => {
        // no-op
    }

    totalElements = () => {
        return this.array.length
    }

    resetRangeToStart = () => {
        this.currentIndex = 0
    }

    resetRangeToEnd = () => {
        this.currentIndex = this.snapshots.length - 1
    }

    setRangeTo = (index: number) => {
        this.currentIndex = index
        this.stop()
    }

    run = () => {
        if (!this._interval) {
            this.interval = setInterval(() => {
                this.currentIndex += 1
            }, this.getAnimationDelay())
        }
    }

    toggleRun = () => {
        console.log('toggleRun', this.currentIndex, this.maxIndex())
        console.log('isRunning', this.isRunning())
        if (this.currentIndex === this.maxIndex()) {
            this.resetRangeToStart()
            this.run()
            console.log('rerun')
        } else if (this.isRunning()) {
            this.stop()
            console.log('stop')
        }
        else {
            this.run()
            console.log('run')
        }
    }

    isRunning = () => {
        return this.interval !== null
    }
    stop = () => {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    }

    private triggerUpdate() {
        if (this._handleUpdate) {
            this._handleUpdate()
        }
    }
}
