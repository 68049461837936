import React, {useRef, useState, useCallback, useEffect} from 'react';
import { SortingVisualization } from '../Utils/SortingVisualization';
import BaseSortingAlgorithm from '../Algorithms/Sorting/BaseSortingAlgorithm';
import AlgorithmVisualization from '../Visualizations/AlgorithmVisualization/AlgorithmVisualization';
import * as ArrayUtils from '../Utils/ArrayUtils';
import './AlgorithmExplorer.css';

interface AlgorithmExplorerProps {
    sortingAlgorithm: BaseSortingAlgorithm
}

const arrayTypes = [
    { name: 'Random', func: ArrayUtils.createRandomArray },
    { name: 'Sorted', func: ArrayUtils.createSortedArray },
    { name: 'Reverse Sorted', func: ArrayUtils.createReverseSortedArray },
    { name: 'Nearly Sorted', func: ArrayUtils.createNearlySortedArray },
    { name: 'Many Duplicates', func: ArrayUtils.createArrayWithManyDuplicates },
    { name: 'Few Unique', func: ArrayUtils.createArrayWithFewUniqueElements },
    { name: 'Sawtooth', func: ArrayUtils.createSawtoothArray },
    { name: 'With Outliers', func: ArrayUtils.createArrayWithOutliers },
];

const AlgorithmExplorer: React.FC<AlgorithmExplorerProps> = ({sortingAlgorithm}) => {
    const input = useRef<HTMLTextAreaElement>(null)
    const randomInput = useRef<HTMLInputElement>(null)
    const [algorithm, setAlgorithm] = useState<SortingVisualization | null>(null)
    const [selectedArrayType, setSelectedArrayType] = useState(arrayTypes[0]);

    useEffect(() => {
        setAlgorithm(sortingAlgorithm(ArrayUtils.createRandomArray(20)))
    }, [sortingAlgorithm])

    const createAlgorithm = useCallback((array: number[]) => {
        setAlgorithm(sortingAlgorithm(array));
    }, [sortingAlgorithm]);

    const handleNewArrayCreation = () => {
        if (input.current) {
            const textInput = input.current.value
            const newArray = textInput.split(",").map(s => parseInt(s.trim()))
            createAlgorithm(newArray);
        }
    }

    const handleRandomArrayCreation = () => {
        if (randomInput.current) {
            const size = parseInt(randomInput.current.value);
            const newArray = selectedArrayType.func(size);
            createAlgorithm(newArray);
        }
    }

    const handleShuffle = (visualization: SortingVisualization) => {
        const newArray = selectedArrayType.func(visualization.totalElements());
        createAlgorithm(newArray);
    }

    if (!algorithm) {
        return <></>
    }

    return (
        <div className='algorithm-explorer'>
            <div className='controls-panel-wrapper'>
                <div className='controls-panel'>
                    <div className='input-array-creator'>
                        <textarea rows={1} cols={40} ref={input} placeholder="Enter comma-separated numbers" />
                        <button onClick={handleNewArrayCreation}>Generate</button>
                    </div>
                    <div className='random-array-creator'>
                        <select 
                            className="array-type-select"
                            value={selectedArrayType.name} 
                            onChange={(e) => setSelectedArrayType(arrayTypes.find(type => type.name === e.target.value) || arrayTypes[0])}
                        >
                            {arrayTypes.map(type => (
                                <option key={type.name} value={type.name}>{type.name}</option>
                            ))}
                        </select>
                        <input ref={randomInput} type='number' defaultValue={20} min={1} max={100} />
                        <button onClick={handleRandomArrayCreation}>Create Array</button>
                    </div>
                </div>
            </div>
            <div className='visualization-container'>
                <AlgorithmVisualization visualization={algorithm} onShuffle={() => handleShuffle(algorithm)} />
            </div>
        </div>
    );
}

export default AlgorithmExplorer;
