import React from "react";

interface BarProps {
    x: number,
    y: number,
    width: number,
    height: number,
    type: string,
    key: string
}

const Bar: React.FC<BarProps> = ({x, y, width, height, type, key}) => {
    return (
        <rect key={key} className={type} x={x} y={y} width={width} height={height}/>
    )
}

export default Bar