import React from "react";

interface SVGProps {
    x?: number,
    y?: number
    width: number,
    height: number,
    marginTop?: number,
    marginBottom?: number,
    marginLeft?: number,
    marginRight?: number,
    children?: React.ReactElement
}

const SVGContainer: React.FC<SVGProps> = ({
                                              children,
                                              marginTop = 20,
                                              marginBottom = 20,
                                              marginRight = 20,
                                              marginLeft = 20,
                                              x = 0,
                                              y = 0,
                                              width,
                                              height
                                          }: SVGProps) => {
    return (
        <svg viewBox={`${x} ${y} ${width} ${height}`}>
            <g transform={`translate(${marginLeft},${marginTop})`}>
                {children && React.cloneElement(children, {
                    width: width - marginLeft - marginRight,
                    height: height - marginTop - marginBottom
                })}
            </g>
        </svg>
    )
}

export default SVGContainer