import Snapshot from "../Snapshot/Snapshot";

class HeapSortSnapshot extends Snapshot {
    copy(): HeapSortSnapshot {
        return new HeapSortSnapshot(
            this.array,
            new Map(this.tags),
            new Map(this.invertedTags)
        );
    }

    highlight(index: number): string {
        switch (index) {
            case this.tags.get('i'):
                return 'green';
            case this.tags.get('largest'):
                return 'red';
        }
        if (index > (this.tags.get('i') ?? -1)) {
            return 'orange';
        }
        return 'skyblue';
    }
}

export default HeapSortSnapshot;
