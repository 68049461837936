import Snapshot from "../Snapshot/Snapshot";

class QuickSortSnapshot extends Snapshot {
    copy(): QuickSortSnapshot {
        return new QuickSortSnapshot(
            this.array,
            new Map(this.tags),
            new Map(this.invertedTags)
        );
    }

    highlight(index: number): string {
        const low = this.tags.get('low') ?? 0;
        const high = this.tags.get('high') ?? this.array.length - 1;
        const pivot = this.tags.get('pivot');
        const left = this.tags.get('left');
        const right = this.tags.get('right');
        const sorted = this.tags.get('sorted') ?? 0;

        if (index < sorted) {
            return 'sorted';
        }

        if (index < low || index > high) {
            return 'inactive';
        }

        if (index === pivot) {
            return 'pivot';
        }

        if (index === left) {
            return 'left-pointer';
        }

        if (index === right) {
            return 'right-pointer';
        }

        if (left !== undefined && right !== undefined) {
            if (index <= left) {
                return 'left-partition';
            }
            if (index >= right) {
                return 'right-partition';
            }
        }

        return 'unsorted';
    }
}

export default QuickSortSnapshot;
