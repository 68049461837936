import React, {ChangeEvent} from "react";
import './VisualizationControls.css';
import ResetToStartButton from "../Buttons/ResetToStartButton";
import DecrementButton from "../Buttons/DecrementButton";
import IncrementButton from "../Buttons/IncrementButton";
import ResetToEndButton from "../Buttons/ResetToEndButton";
import ShuffleButton from "../Buttons/ShuffleButton";
import PlayPauseButton from "../Buttons/PlayPauseButton";
import ProgressRange from "../ProgressRange/ProgressRange";

interface ControlProps {
    maxIndex: number,
    incrementRange: () => void
    decrementRange: () => void
    resetRangeToStart: () => void
    resetRangeToEnd: () => void
    currentIndex: () => number
    setIndex: (index: number) => void
    toggleRun: () => void
    isRunning: () => boolean
    shuffle: () => void
    changeAnimationSpeed: (time: number) => void
    getAnimationSpeed: () => number
}

const VisualizationControls: React.FC<ControlProps> = (
    {
        maxIndex,
        incrementRange,
        decrementRange,
        resetRangeToStart,
        resetRangeToEnd,
        currentIndex,
        setIndex,
        toggleRun,
        isRunning,
        shuffle,
        changeAnimationSpeed,
        getAnimationSpeed
    }) => {

    function handleAnimationSpeedChange(event: ChangeEvent<HTMLInputElement>) {
        changeAnimationSpeed(1000 - parseInt(event.target.value))
    }

    return (
        <div className={'control-panel'}>
            <div className={'controls'}>
                <div className="speed-control">
                    <input 
                        className={'speed-range'} 
                        type={'range'} 
                        min={0} 
                        max={1000} 
                        value={1000 - getAnimationSpeed()}
                        onChange={handleAnimationSpeedChange}
                    />
                </div>
                <div className="control-buttons">
                    <ResetToStartButton resetRangeToStart={resetRangeToStart} />
                    <DecrementButton decrementRange={decrementRange} />
                    <PlayPauseButton 
                        toggleRun={toggleRun}
                        isRunning={isRunning}
                        currentIndex={currentIndex}
                        maxIndex={maxIndex}
                    />
                    <IncrementButton incrementRange={incrementRange} />
                    <ResetToEndButton resetRangeToEnd={resetRangeToEnd} />
                    <ShuffleButton shuffle={shuffle} />
                </div>
                <ProgressRange 
                    maxIndex={maxIndex}
                    currentIndex={currentIndex}
                    setIndex={setIndex}
                />
            </div>
        </div>
    )
}

export default VisualizationControls
