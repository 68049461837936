import Snapshots from "../common/Snapshots";
import { SortingVisualization } from "../../../Utils/SortingVisualization";
import MergeSortSnapshot from "./MergeSortSnapshot";
import ArrayElement from "../common/ArrayElement";
import BaseSortingAlgorithm from "../BaseSortingAlgorithm";

const MergeSort: BaseSortingAlgorithm = (data: number[]): SortingVisualization => {
    const s = new Snapshots(MergeSortSnapshot);

    let array: ArrayElement[] = data.map((v, i) => ({
        id: i,
        value: v
    }));
    s.withArray(array);

    const shiftAndPush = (source: ArrayElement[], destination: ArrayElement[]): void => {
        const element = source.shift();
        if (element) destination.push(element);
    };

    const mergeSort = (arr: ArrayElement[], start: number, end: number): void => {
        if (end - start <= 1) return;

        const middle = Math.floor((start + end) / 2);

        s.withTags(["start", start], ["middle", middle], ["end", end], ["sortedEnd", start]);

        mergeSort(arr, start, middle);
        mergeSort(arr, middle, end);

        merge(arr, start, middle, end);
    };

    const createMergedArray = (arr: ArrayElement[], start: number, end: number, temp: ArrayElement[], leftQueue: ArrayElement[], rightQueue: ArrayElement[]): ArrayElement[] =>  {
        return [...arr.slice(0, start), ...temp, ...leftQueue, ...rightQueue, ...arr.slice(end)];
    }

    const merge = (arr: ArrayElement[], start: number, middle: number, end: number): void => {
        const leftQueue: ArrayElement[] = arr.slice(start, middle);
        const rightQueue: ArrayElement[] = arr.slice(middle, end);
        const temp: ArrayElement[] = [];

        while (leftQueue.length > 0 && rightQueue.length > 0) {
            s.withTags(
                ["start", start],
                ["middle", middle],
                ["end", end],
                ["sortedEnd", start + temp.length],
                ["leftIndex", start + temp.length],
                ["rightIndex", middle + rightQueue.length - 1]
            );

            if (leftQueue[0].value <= rightQueue[0].value) {
                shiftAndPush(leftQueue, temp);
            } else {
                shiftAndPush(rightQueue, temp);
            }
            s.withArray(createMergedArray(arr, start, end, temp, leftQueue, rightQueue));
        }

        while (leftQueue.length > 0) {
            s.withTags(
                ["start", start],
                ["middle", middle],
                ["end", end],
                ["sortedEnd", start + temp.length],
                ["leftIndex", start + temp.length]
            );
            shiftAndPush(leftQueue, temp);
            s.withArray(createMergedArray(arr, start, end, temp, leftQueue, rightQueue));
        }

        while (rightQueue.length > 0) {
            s.withTags(
                ["start", start],
                ["middle", middle],
                ["end", end],
                ["sortedEnd", start + temp.length],
                ["rightIndex", middle + rightQueue.length - 1]
            );
            shiftAndPush(rightQueue, temp);
            s.withArray(createMergedArray(arr, start, end, temp, leftQueue, rightQueue));
        }

        for (let i = 0; i < temp.length; i++) {
            arr[start + i] = temp[i];
        }

        s.withTags(["start", start], ["middle", middle], ["end", end], ["sortedEnd", end]);
        s.withArray(arr);
    };

    mergeSort(array, 0, array.length);
    s.withArray(array);

    return new SortingVisualization(data, s.snapshots);
};

export default MergeSort;
