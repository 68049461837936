import Snapshots from "../common/Snapshots";
import { SortingVisualization } from "../../../Utils/SortingVisualization";
import QuickSortSnapshot from "./QuickSortSnapshot";
import ArrayElement from "../common/ArrayElement";
import BaseSortingAlgorithm from "../BaseSortingAlgorithm";

const QuickSort: BaseSortingAlgorithm = (data: number[]): SortingVisualization => {
    const s = new Snapshots(QuickSortSnapshot);

    let array: ArrayElement[] = data.map((v, i) => ({
        id: i,
        value: v
    }));
    s.withArray(array);
    s.withTags(["sorted", 0]);  // Initialize sorted tag to 0

    const partition = (arr: ArrayElement[], low: number, high: number): number => {
        const middleIndex = Math.floor((low + high) / 2);
        const pivot = arr[middleIndex].value;
        
        s.withTags(["low", low], ["high", high], ["pivot", middleIndex], ["sorted", low]);

        let left = low;
        let right = high;

        while (left <= right) {
            while (arr[left].value < pivot) {
                left++;
                s.withTags(["low", low], ["high", high], ["pivot", middleIndex], ["left", left], ["right", right], ["sorted", low]);
            }
            while (arr[right].value > pivot) {
                right--;
                s.withTags(["low", low], ["high", high], ["pivot", middleIndex], ["left", left], ["right", right], ["sorted", low]);
            }
            if (left <= right) {
                [arr[left], arr[right]] = [arr[right], arr[left]];
                s.withArray(arr);
                s.withTags(["low", low], ["high", high], ["pivot", middleIndex], ["left", left], ["right", right], ["sorted", low]);
                left++;
                right--;
            }
        }

        return left;
    }

    const quickSort = (arr: ArrayElement[], low: number, high: number, sortedIndex: number): number => {
        if (low < high) {
            const pivotIndex = partition(arr, low, high);
            
            sortedIndex = quickSort(arr, low, pivotIndex - 1, sortedIndex);
            sortedIndex = quickSort(arr, pivotIndex, high, sortedIndex);

            // Update sorted tag after each recursive call
            sortedIndex = Math.max(sortedIndex, low);
            s.withTags(["sorted", sortedIndex]);
        } else {
            sortedIndex = Math.max(sortedIndex, high + 1);
            s.withTags(["sorted", sortedIndex]);
        }
        return sortedIndex;
    }

    quickSort(array, 0, array.length - 1, 0);
    s.withTags(["sorted", array.length]);
    s.withArray(array);

    return new SortingVisualization(data, s.snapshots);
}

export default QuickSort;
