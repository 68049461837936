import React from 'react';

interface IncrementButtonProps {
  incrementRange: () => void;
}

const IncrementButton: React.FC<IncrementButtonProps> = ({ incrementRange }) => (
  <button onClick={incrementRange}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6L8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6Z" fill="#e0e0e0"/>
    </svg>
  </button>
);

export default IncrementButton;
