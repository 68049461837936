import ArrayElement from "./ArrayElement";
import Snapshot from "../Snapshot/Snapshot";

class Snapshots<T extends Snapshot> {
    #snapshots: T[] = [];
    private snapshotConstructor: new (array: ArrayElement[]) => T;

    constructor(snapshotConstructor: new (array: ArrayElement[]) => T) {
        this.snapshotConstructor = snapshotConstructor;
    }

    private get lastSnapshot(): T {
        return this.#snapshots[this.#snapshots.length - 1];
    }

    withArray = (array: ArrayElement[]) => {
        if (this.#snapshots.length === 0) {
            this.#snapshots.push(new this.snapshotConstructor([...array]));
        } else {
            this.#snapshots.push(this.lastSnapshot.withArray([...array]) as T);
        }
    }

    withTag = (tag: string, value: number) => {
        if (this.#snapshots.length === 0) {
            this.#snapshots.push(new this.snapshotConstructor([]).withTag(tag, value) as T);
        } else {
            this.#snapshots.push(this.lastSnapshot.withTag(tag, value) as T);
        }
    }

    resetTag = (tag: string) => {
        this.withTag(tag, -1);
    }

    withTags = (...tagPairs: [string, number][]) => {
        if (this.#snapshots.length === 0) {
            this.#snapshots.push(new this.snapshotConstructor([]).withTags(new Map(tagPairs)) as T);
        } else {
            this.#snapshots.push(this.lastSnapshot.withTags(new Map(tagPairs)) as T);
        }
    }
    
    resetTags = (...tags: string[]) => {
        this.withTags(...tags.map(tag => [tag, -1] as [string, number]));
    }

    get snapshots(): T[] {
        return this.#snapshots;
    }
}

export default Snapshots;
