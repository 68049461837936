import React from 'react';

interface ResetToStartButtonProps {
  resetRangeToStart: () => void;
}

const ResetToStartButton: React.FC<ResetToStartButtonProps> = ({ resetRangeToStart }) => (
  <button onClick={resetRangeToStart}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6H10V18H6V6ZM11.5 12L18 6V18L11.5 12Z" fill="#e0e0e0"/>
    </svg>
  </button>
);

export default ResetToStartButton;
