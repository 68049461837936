import Snapshots from "../common/Snapshots";
import { SortingVisualization } from "../../../Utils/SortingVisualization";
import HeapSortSnapshot from "./HeapSortSnapshot";
import ArrayElement from "../common/ArrayElement";
import BaseSortingAlgorithm from "../BaseSortingAlgorithm";

const HeapSort: BaseSortingAlgorithm = (data: number[]): SortingVisualization => {
    const s = new Snapshots(HeapSortSnapshot);

    let array: ArrayElement[] = data.map((v, i) => ({
        id: i,
        value: v
    }));
    s.withArray(array);

    const heapify = (arr: ArrayElement[], n: number, i: number): void => {
        let largest = i;
        const left = 2 * i + 1;
        const right = 2 * i + 2;

        if (left < n && arr[left].value > arr[largest].value) {
            largest = left;
        }

        if (right < n && arr[right].value > arr[largest].value) {
            largest = right;
        }

        if (largest !== i) {
            s.withTags(["i", i], ["largest", largest]);
            [arr[i], arr[largest]] = [arr[largest], arr[i]];
            s.withArray(arr);

            heapify(arr, n, largest);
        }
    }

    const buildHeap = (arr: ArrayElement[]): void => {
        const n = arr.length;
        for (let i = Math.floor(n / 2) - 1; i >= 0; i--) {
            heapify(arr, n, i);
        }
    }

    buildHeap(array);

    for (let i = array.length - 1; i > 0; i--) {
        s.withTag("i", i);
        [array[0], array[i]] = [array[i], array[0]];
        s.withArray(array);

        heapify(array, i, 0);
    }

    s.withTag("i", Number.MAX_SAFE_INTEGER);

    return new SortingVisualization(data, s.snapshots);
}

export default HeapSort;
