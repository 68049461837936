import React from 'react';
import './styles/App.css';
import AlgorithmExplorer from "./AlgorithmExplorer/AlgorithmExplorer";
import {Routes, Route, useLocation, Link} from "react-router-dom";
import AlgorithmGrid from './AlgorithmGrid/AlgorithmGrid';
import SortingAlgorithmsGrid from './SortingAlgorithmsGrid/SortingAlgorithmsGrid';
import SelectionSort from './Algorithms/Sorting/SelectionSort/SelectionSort';
import BubbleSort from './Algorithms/Sorting/BubbleSort/BubbleSort';
import InsertionSort from './Algorithms/Sorting/InsertionSort/InsertionSort';
import HeapSort from './Algorithms/Sorting/HeapSort/HeapSort';
import MergeSort from './Algorithms/Sorting/MergeSort/MergeSort';
import QuickSort from './Algorithms/Sorting/QuickSort/QuickSort';

const App = () => {
    const location = useLocation();
    const isMainPage = location.pathname === '/';

    const getAlgorithmName = () => {
        const path = location.pathname;
        if (path.includes('selection-sort')) return 'Selection Sort';
        if (path.includes('bubble-sort')) return 'Bubble Sort';
        if (path.includes('insertion-sort')) return 'Insertion Sort';
        if (path.includes('heap-sort')) return 'Heap Sort';
        if (path.includes('merge-sort')) return 'Merge Sort';
        if (path.includes('quick-sort')) return 'Quick Sort';
        return '';
    };

    const algorithmName = getAlgorithmName();

    return (
        <div className="app-container">
            <header className="app-header">
                <div className="header-left">
                    {isMainPage ? (
                        <h1 className="app-title">bigO🥷</h1>
                    ) : (
                        <Link to="/" className="app-title">bigO🥷</Link>
                    )}
                    {!isMainPage && algorithmName && <h2 className="algorithm-name">{algorithmName}</h2>}
                </div>
                <button className="login-button">Login</button>
            </header>
            <div className="content-container">
                {isMainPage ? (
                    <AlgorithmGrid />
                ) : (
                    <Routes>
                        <Route path='/sorting' element={<SortingAlgorithmsGrid />} />
                        <Route path='/sorting/selection-sort' element={<AlgorithmExplorer sortingAlgorithm={SelectionSort} />} />
                        <Route path='/sorting/bubble-sort' element={<AlgorithmExplorer sortingAlgorithm={BubbleSort} />} />
                        <Route path='/sorting/insertion-sort' element={<AlgorithmExplorer sortingAlgorithm={InsertionSort} />} />
                        <Route path='/sorting/heap-sort' element={<AlgorithmExplorer sortingAlgorithm={HeapSort} />} />
                        <Route path='/sorting/merge-sort' element={<AlgorithmExplorer sortingAlgorithm={MergeSort} />} />
                        <Route path='/sorting/quick-sort' element={<AlgorithmExplorer sortingAlgorithm={QuickSort} />} />
                    </Routes>
                )}
            </div>
        </div>
    );
}

export default App;
